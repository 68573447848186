
import { Options, Vue } from 'vue-class-component'
import { ElMessage } from 'element-plus'
import axios from '@/plugins/axios'
import { ElMessageBox } from 'element-plus'
import { VueDraggableNext } from 'vue-draggable-next'
import ProductOptionChild from './ProductOption.vue'
import OrderOptionChild from './OrderOption.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

interface Res {
  list: []
  total: number
}
interface Response {
  message: ''
  status: boolean
  id: ''
}
interface Result {
  data: []
}
@Options({
  components: {
    draggable: VueDraggableNext,
    ProductOptionChild,
    OrderOptionChild,
    FontAwesomeIcon
  },
  props: {
    setting: {}
  }
})
export default class ScriptSettingPage extends Vue {
  listScript = [
    {
      connectFrom: { _id: '' },
      connectTo: { _id: '' },
      expiried: false,
      status: false
    }
  ]
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  accountId = localStorage.getItem('account')
  accountName = ''
  dialogForm = false
  dialogUpdateSetup = false
  isShowAddMore = false
  isTypeProductOrOrderOpposite = true
  refForm = ''
  option = ''
  activeName = 'first'
  net: any
  listExpiryDate = [{}]
  listConnectionExpiryDate = [{ _id: '' }]
  accounts = []
  listAccount = []
  listFrom = []
  listTo = []
  listConnect = []
  listScriptCustom = []
  listScriptCustomUpdate = [
    {
      account: localStorage.getItem('account'),
      cloneId: '',
      _id: '',
      status: false,
      createdBy: '',
      isTemplate: false,
      index: 0,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      title: '',
      global: true,
      applyTo: [
        {
          _id: ''
        }
      ]
    }
  ]
  listScriptCustomUpdateFilter = [...this.listScriptCustomUpdate]
  listAddMoreScriptCustom = []
  currentPage = 1
  totaRows = 0
  perPage = 20
  condition = {
    connectionFromType: '',
    connectionToType: '',
    isTemplate: true,
    type: ''
  }
  formData: any = {
    type: '',
    name: '',
    version: 'old',
    status: false,
    enableAllScript: false,
    timeCron: 15,
    listScriptCustom: [
      {
        cloneId: '',
        _id: '',
        status: false,
        createdBy: '',
        isTemplate: false,
        index: 0,
        global: true,
        applyTo: [
          {
            _id: ''
          }
        ]
      }
    ]
  }
  dataCopy = { ...this.formData }
  formDataSetup = {
    _id: '',
    enableAllScript: false,
    disable: false,
    status: false,
    expiried: false,
    type: '',
    connectFrom: {
      type: '',
      name: ''
    },
    connectTo: {
      type: '',
      name: ''
    },
    user: {
      disableByAdmin: false
    },
    listScriptCustom: [
      {
        cloneId: '',
        _id: '',
        status: false,
        createdBy: '',
        isTemplate: false,
        index: 0,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        title: '',
        global: true,
        applyTo: [
          {
            _id: ''
          }
        ]
      }
    ],
    isSyncProductDeletion: false,
    isSyncOrder: false,
    numberOrderImportAtATime: 1,
    updateQuantityType: 'Absolute',
    version: 'old',
  }
  rules = {
    name: [
      {
        required: true,
        message: 'Please input name',
        trigger: 'blur'
      }
    ],
    type: [
      {
        required: true,
        message: 'Please input type',
        trigger: 'blur'
      }
    ],
    connectFrom: [
      {
        required: true,
        message: 'Please choose connect from',
        trigger: 'blur'
      }
    ],
    connectTo: [
      {
        required: true,
        message: 'Please choose connect to',
        trigger: 'blur'
      }
    ],
    timeCron: [
      {
        required: true,
        message: 'Please choose timeCron',
        trigger: 'blur'
      }
    ] 
  }
  checkDisabledCron(row: any) {
    return row.user.disableByAdmin || row.disable || row.expiried || this.checkDisabledConnection(row)
  }

  checkDisabledConnection(row: any) {
    let isDisable = false
    for (const targetConnection of this.listTo as any[]) {
      if ([row.connectFrom._id, row.connectTo._id].includes(targetConnection._id)) {
        if (targetConnection.is_active === false) {
          isDisable = true
        }
      }
    }
    return isDisable
  }

  async created() {
    this.listScript.shift()
    this.formData.listScriptCustom = []
    this.accountId = localStorage.getItem('account')
    this.accounts = await this.$store.state.auth.dataLogin.account
    await this.getListScript(0)
    await this.getListConnect()
    await this.getListAccount()
    await this.setAccountName()
    await this.getProductAttribute()
  }
  getProductAttribute() {
    axios
      .get('user/product/attribute', {
        headers: this.headers
      })
      .then()
      .catch((err) => {
        console.log(err)
      })
  }
  async getListAccount() {
    let res: Result
    res = await axios.get('user/list', {
      headers: this.headers,
      params: {
        page: 1,
        limit: 90000
      }
    })
    if (res) {
      this.listAccount = res.data
    }
  }
  async account() {
    await this.$store.dispatch('setLoading', true, { root: true })
    await axios
      .post('user/account/detail', {
        headers: this.headers,
        params: {
          account: this.accountId
        }
      })
      .then((response) => {
        this.net = response
        this.net.data.connectionData.forEach((element: any) => {
          if (!element.isActive) {
            this.listExpiryDate.push(...element.connections)
          }
        })
        this.listExpiryDate.shift()
        this.listExpiryDate.forEach((element: any) => {
          if (element.from == false) {
            this.listConnectionExpiryDate.push(element.connection)
          }
        })
        this.listConnectionExpiryDate.shift()
      })
      .catch((error) => {
        console.log(error)
      })
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  clickAdd() {
    this.dialogForm = true
  }
  handleCurrentChange(index = 1) {
    this.currentPage = index
    this.getListScript(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    this.getListScript(this.currentPage)
  }
  setAccountName() {
    this.accounts.forEach((acc) => {
      if (acc['_id'] === this.accountId) {
        return (this.accountName = acc['name'])
      }
    })
  }
  async beforeSave() {
    this.refForm = 'formNew'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })
    let formDataType = this.formData.type
    if (formDataType === 'order_opposite') {
      formDataType = 'order'
    }
    if (formDataType === 'productQTYOpposite') {
      formDataType = 'productQTY'
    }
    this.condition.connectionFromType =
      this.formData.connectFrom.type.toLowerCase()
    this.condition.connectionToType = this.formData.connectTo.type.toLowerCase()
    this.condition.type = formDataType
    await this.getListScriptCustom()
    this.dialogForm = false
    this.formData.listScriptCustom.forEach((item: any) => {
      item.cloneId = this.mongoObjectId()
      item._id = this.mongoObjectId()
      item.createdBy = this.accountName
      item.isTemplate = false
      item.connectionFromName = this.formData.connectFrom.name
      item.connectionToName = this.formData.connectTo.name
      item.index = this.formData.listScriptCustom.indexOf(item)
    })
    this.option = 'optionScript'
    if (formDataType === 'product') {
      eval(
        'const obj' +
        ' = ' +
        '{' +
        this.option +
        ' : ' +
        `
        {
          syncMode: 'productInfoAndQuatity',
          isUploadImg: true,
          allowToCreateProduct: true,
        }
        ` +
        '}' +
        '\n' +
        'this.formData = {...this.formData, ...obj}'
      )
    } else {
      eval(
        'const obj' +
        ' = ' +
        '{' +
        this.option +
        ' : ' +
        `
        {
          onlySyncOrderFromMarketplace: [],
          onlySyncOrderFromDistributionCenterID: [],
          enableOrderConfirmationNotification: [],
          defaultShippingMethod: '${this.capitalizeTxt(
          this.formData.connectFrom.type
        )}',
          defaultPaymentMethod: '${this.capitalizeTxt(
          this.formData.connectFrom.type
        )}',
        }
        ` +
        '}' +
        '\n' +
        'this.formData = {...this.formData, ...obj}'
      )
    }

    await this.handleSaveSetup()
  }
  async getListScript(page = 0) {
    let res: Res = {
      list: [],
      total: 0
    }
    await this.$store.dispatch('setLoading', true, { root: true })
    await this.account()
    res = await axios.get('user/setting-script', {
      headers: this.headers,
      params: {
        account: this.accountId,
        page: page,
        limit: this.perPage
      }
    })
    if (res) {
      this.listScript = res.list
      this.totaRows = res.total
      this.listConnectionExpiryDate.forEach((connect) => {
        this.listScript.forEach((cron) => {
          if (
            connect._id === cron.connectFrom._id ||
            connect._id === cron.connectTo._id
          ) {
            cron.expiried = true
            cron.status = false
          }
        })
      })
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getListConnect() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result = {
      data: []
    }
    res = await axios.get('user/connect/list', {
      headers: this.headers,
      params: {
        account: this.accountId
      }
    })
    if (res) {
      this.listConnect = res.data
      for (let obj of this.listConnect) {
        if (obj['connectFrom'] == true) {
          this.listFrom.push(obj)
        } else {
          this.listTo.push(obj)
        }
      }
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getListScriptCustom() {
    let res: Res = {
      list: [],
      total: 0
    }
    res = await axios.get('user/script-custom/list-origin', {
      headers: this.headers,
      params: {
        condition: this.condition
      }
    })
    if (res) {
      res.list.forEach((item: any) => {
        if (item.global == true) {
          this.formData.listScriptCustom.push(item)
        }
        if (item.global == false && item.applyTo.length > 0) {
          let result = item.applyTo.find(
            ({ _id }: any) => _id === this.accountId
          )
          if (result) {
            this.formData.listScriptCustom.push(item)
          }
        }
      })
    }
  }
  async clickDelete(item: any) {
    await ElMessageBox.confirm('Do you really want to delete ?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    })
      .then(() => {
        this.onDelete(item, this.headers)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  async onDelete(item: any, headers: any) {
    var id = item._id
    item.listScriptCustom.forEach((i: any) => {
      i.deleted = true
      i.status = false
    })
    this.dataCopy = { ...item }
    await this.$store.dispatch('setLoading', true, { root: true })
    await axios
      .post(
        'user/setting-script/delete',
        { id: id },
        {
          headers: this.headers
        }
      )
      .then()
      .catch(function (error) {
        console.log(error)
      })
    await axios
      .post('user/script-custom/delete-many', this.dataCopy.listScriptCustom, {
        headers: this.headers
      })
      .then(function (response) {
        if (response.status) {
          ElMessage.success('Delete successfully !!!')
        } else {
          ElMessage.error('Something wrong !!!')
        }
      })
      .catch(function (error) {
        ElMessage.error('Something wrong !!!')
        console.log(error)
      })
    await this.getListScript(this.currentPage)
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  capitalizeTxt(str: any) {
    var firstLetter = str.substr(0, 1)
    return firstLetter.toUpperCase() + str.substr(1)
  }
  async clickSetup(item: any) {
    this.dialogUpdateSetup = true
    this.condition.connectionFromType = item.connectFrom.type.toLowerCase()
    this.condition.connectionToType = item.connectTo.type.toLowerCase()
    this.condition.type = item.type
    this.formDataSetup = item
    this.option = 'optionScript'

    let res: Res = await axios.get('user/script-custom/list-origin', {
      headers: this.headers,
      params: {
        condition: this.condition
      }
    })
    if (res) {
      this.listScriptCustomUpdate = res.list
      this.listScriptCustomUpdateFilter = this.listScriptCustomUpdate.filter(
        (entry1) =>
          !this.formDataSetup.listScriptCustom.some(
            (entry2) => entry1.title === entry2.title
          )
      )
      this.listScriptCustomUpdateFilter.forEach((element) => {
        if (!element.global) {
          const result = element.applyTo.find(
            ({ _id }) => _id === this.accountId
          )
          if (result) {
            element._id = this.mongoObjectId()
            element.cloneId = this.mongoObjectId()
            element.isTemplate = false
            element.createdBy = this.accountName
            this.formDataSetup.listScriptCustom.push(element)
          }
        } else {
          element._id = this.mongoObjectId()
          element.cloneId = this.mongoObjectId()
          element.isTemplate = false
          element.createdBy = this.accountName
          this.formDataSetup.listScriptCustom.push(element)
        }
      })
    }
  }
  mongoObjectId() {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
    return (
      timestamp +
      'xxxxxxxxxxxxxxxx'
        .replace(/[x]/g, function () {
          return ((Math.random() * 16) | 0).toString(16)
        })
        .toLowerCase()
    )
  }
  async handleSaveSetup() {
    this.dataCopy = { ...this.formData }
    if (this.dataCopy.type === 'order_opposite') {
      this.dataCopy.type = 'order'
    }
    if (this.dataCopy.type === 'productQTYOpposite') {
      this.dataCopy.type = 'productQTY'
    }
    let nextStep = true
    let res: Response = await axios.post('user/setting-script', this.dataCopy, {
      headers: this.headers,
      params: {
        account: this.accountId
      }
    })
    if (res.status == false) {
      nextStep = false
      ElMessage.error(res.message)
      this.dialogForm = false
      await this.$store.dispatch('setLoading', false, { root: true })
    }
    this.dataCopy.listScriptCustom.forEach((custom: any) => {
      custom.cronId = res.id
    })
    if (nextStep) {
      await axios
        .post('user/script-custom', this.dataCopy.listScriptCustom, {
          headers: this.headers,
          params: {
            account: this.accountId
          }
        })
        .then(function (response) {
          if (response.status) {
            ElMessage.success('Create successfully !!!')
          } else {
            ElMessage.error('Something wrong !!!')
          }
        })
        .catch(function (error) {
          ElMessage.error('Something wrong !!!')
          console.log(error)
        })
      this.getListScript(this.currentPage)
      this.dialogForm = false
      await this.$store.dispatch('setLoading', false, { root: true })
    }
  }
  async handleUpdateSetup() {
    let nextStep = true
    this.refForm = 'formSetup'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    this.formDataSetup.listScriptCustom.forEach((item) => {
      item.index = this.formDataSetup.listScriptCustom.indexOf(item)
    })
    eval(`
        if(this.formDataSetup.${this.option}.syncMode === "productQuatityOnly" && this.formDataSetup.${this.option}.isUploadImg == true) {
            this.formDataSetup.${this.option}.isUploadImg = false;
        }
  `)
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Response = await axios.put(
      'user/setting-script',
      this.formDataSetup,
      {
        headers: this.headers,
        params: {
          account: this.accountId
        }
      }
    )
    if (res) {
      if (res.status == false) {
        ElMessage.error(res.message)
        nextStep = false
        this.dialogUpdateSetup = false
        await this.$store.dispatch('setLoading', false, { root: true })
      }
    }
    if (nextStep) {
      await axios
        .put(
          'user/script-custom/update-many',
          this.formDataSetup.listScriptCustom,
          {
            headers: this.headers,
            params: {
              account: this.accountId
            }
          }
        )
        .then(function () {
          ElMessage.success('Update successfully !!!')
        })
        .catch(function (error) {
          ElMessage.error('Something wrong !!!')
          console.log(error)
        })
      this.getListScript(this.currentPage)
      this.dialogUpdateSetup = false
      await this.$store.dispatch('setLoading', false, { root: true })
    }
  }
  resetFormNew() {
    this.formData.status = false
    this.activeName = 'first'
    this.refForm = 'formNew'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    return refFrorm.resetFields()
  }
  async resetFormSetup() {
    this.formData.status = false
    this.activeName = 'first'
    // this.refForm = "formSetup";
    // const refFrorm = this?.$refs[this.refForm] as HTMLFormElement;
    // return refFrorm.resetFields();
  }
  selectTypeConnect(type: any) {
    if (
      type === 'product' ||
      type === 'order_opposite' ||
      type === 'productQTY'
    ) {
      this.isTypeProductOrOrderOpposite = true
    } else this.isTypeProductOrOrderOpposite = false
  }
  beforeChange1() {
    return new Promise((resolve, reject) => {
      ElMessageBox.confirm('Do you really want to change status ?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          return resolve(true)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  }
  async toogleStatus(item: any) {
    let nextStep = true
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Response = await axios.get('check-vps', {})
    if (res) {
      if (res.status == false) {
        ElMessage.error(res.message)
        nextStep = false
        await this.$store.dispatch('setLoading', false, { root: true })
      }
    }
    if (nextStep) {
      await axios
        .post(
          `user/setting-script/toggleCronStatus/${item._id}`,
          {},
          {
            headers: this.headers,
            params: {
              account: this.accountId
            }
          }
        )
        .then(function (response) {
          if (response.status) {
            ElMessage.success('Update Status successfully !!!')
          } else {
            ElMessage.error('Something wrong !!!')
            item.status = !item.status
          }
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error('Something wrong !!!')
          item.status = !item.status
        })
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handlePushSiteName(siteName: any) {
    eval(
      `this.formDataSetup.${this.option}.onlySyncOrderFromMarketplace.push(siteName)`
    )
  }
  handlePushSKU(sku: any) {
    eval(`this.formDataSetup.${this.option}.onlySyncProductSKU.push(sku)`)
  }
  handleRemoveSKU(sku: any) {
    eval(
      `this.formDataSetup.${this.option}.onlySyncProductSKU.splice(this.formDataSetup.${this.option}.onlySyncProductSKU.indexOf(sku), 1)`
    )
  }
  handleRemoveSiteName(siteName: any) {
    eval(
      `this.formDataSetup.${this.option}.onlySyncOrderFromMarketplace.splice(this.formDataSetup.${this.option}.onlySyncOrderFromMarketplace.indexOf(siteName), 1)`
    )
  }
  handlePushDistributionCenterID(id: any) {
    eval(
      `this.formDataSetup.${this.option}.onlySyncOrderFromDistributionCenterID.push(id)`
    )
  }
  handleRemoveDistributionCenterID(id: any) {
    eval(
      `this.formDataSetup.${this.option}.onlySyncOrderFromDistributionCenterID.splice(this.formDataSetup.${this.option}.onlySyncOrderFromDistributionCenterID.indexOf(id), 1)`
    )
  }
  handlePushMetafield(item: any) {
    eval(`this.formDataSetup.${this.option}.metafields.push(item)`)
  }
  handleRemoveMetafield(item: any) {
    eval(
      `this.formDataSetup.${this.option}.metafields.splice(this.formDataSetup.${this.option}.metafields.indexOf(item), 1);
       if(
        this.formDataSetup.${this.option}.metafields.length == 0
        ){
         this.formDataSetup.${this.option}.hasMetafields = false;
        }
            `
    )
  }
  handlePushMarketplace(marketplace: any) {
    let mkps = marketplace.split(',')
    for (let market of mkps) {
      market = market.trim()
      eval(
        `this.formDataSetup.${this.option}.enableOrderConfirmationNotification.push(market)`
      )
    }
  }
  handleRemoveMarketplace(marketplace: any) {
    eval(
      `this.formDataSetup.${this.option}.enableOrderConfirmationNotification.splice(this.formDataSetup.${this.option}.enableOrderConfirmationNotification.indexOf(marketplace), 1)`
    )
  }
}
